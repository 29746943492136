<template>
    <div id="check_order">
        <div class="send_buy_block">
            <div class="title">
                根据输入内容，核对收件人信息。
                <span @click="$router.go(-1)">返回重新上传>></span>
            </div>
            <div class="sub_title">根据输入内容，核对收件人信息，出现地址错误，点击地址编辑，完善省市区等信息即可。</div>
            <div class="excel_list_check">
                <el-button type="success" size="small" style="margin-top: 5px;margin-bottom: 5px" @click="sortDataAkey()">有误排序</el-button>
                <table>
                    <tr>
                        <td>#</td>
                        <td>第三方订单编号</td>
                        <td width="40px">收货人</td>
                        <td>收货人手机号</td>
                        <td width="200px">地址</td>
                        <td width="200px">商品名</td>
                        <td>校验结果</td>
                        <td>操作</td>
                    </tr>
                    <tr v-for="(item,index) in list">
                        <td>{{index + 1}}</td>
                        <td>{{item.orderNo}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.phone}}</td>
                        <td>{{item.province}}{{item.city}}{{item.county}}{{item.address}}</td>
                        <td>{{item.tradeName}}</td>
                        <td>
                            <el-tooltip class="item" effect="dark" :content="getHtmlContent(item.html)" placement="top-end">
                                <div  style="width: 4rem; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; text-align: center;" v-html="item.html"></div>
                            </el-tooltip>
                        </td>
                        <td class="handle">
                            <p><el-button size="small" @click="showEdit(index)">编辑</el-button></p>
                            <p><el-button size="small" @click="del(index)" type="danger" plain>删除</el-button></p>
                        </td>
                    </tr>
                </table>
                <!-- <div v-if="editObj.phone" class="edit_div">
                    <div style="width:130px"><el-input size="small" v-model="editObj.phone" placeholder="电话号码"></el-input></div>
                    <div style="width:100px"><el-input size="small" v-model="editObj.province" placeholder="省"></el-input></div>
                    <div style="width:100px"><el-input size="small" v-model="editObj.city" placeholder="市"></el-input></div>
                    <div style="width:100px"><el-input size="small" v-model="editObj.county" placeholder="区"></el-input></div>
                    <div style="width:280px"><el-input size="small" v-model="editObj.address" placeholder="详细地址"></el-input></div>
                    <el-button size="small" type="primary" @click="checkEdit">确定</el-button>
                </div> -->
                <div class="btn">
                    <el-button size="small" @click="next">下一步</el-button>
                </div>
            </div>
        </div>
        <el-dialog
            title="编辑收件人地址"
            width="600px"
            center
            :visible.sync="showTool">
            <div class="input_put_block" v-if="showTool">
                <div class="put_item">
                    <div class="item">
                        <span>姓名:</span>
                        <input type="text" v-model="editObj.name" placeholder="姓名">
                    </div>
                    <div class="item">
                        <span>电话:</span>
                        <input type="text" v-model="editObj.phone" placeholder="电话">
                    </div>
                    <div class="item">
                        <span>省:</span>
                        <input type="text" v-model="editObj.province" placeholder="省">
                    </div>
                    <div class="item">
                        <span>市:</span>
                        <input type="text" v-model="editObj.city" placeholder="市">
                    </div>
                    <div class="item">
                        <span>区:</span>
                        <input type="text" v-model="editObj.county" placeholder="区">
                    </div>
                    <div class="item">
                        <span>详细地址:</span>
                        <input type="text" v-model="editObj.address" placeholder="详细地址">
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" type="danger" @click="checkEdit">确认修改</el-button>
                <el-button size="small" type="defauil" @click="showTool = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            checkIndex: '',
            list: [],
            state: true,
            editObj: {
                name: '',
                phone: '',
                html: '',
                state: '',
                province: '',
                city: '',
                county: '',
                address: '',
                index: '',
            },
            platform: '',
            importType: '',
            shopId: '',
            showTool: false,
            errorList: []
        }
    },
    created(){
        let obj = sessionStorage.getItem('real_list_data')
        obj = JSON.parse(obj)
        let item = obj.list
        this.platform = obj.platform
        this.importType = obj.importType
        this.shopId = obj.shopId
        item.forEach((res,index) => {
            res.html = '<span style="color:#10b573">正确</span>'
            res.state = 1
            let phone = res.phone
            phone = phone.indexOf('-')!=-1?phone.split('-')[1]:phone
            if(!this.$check.isPhone(phone)){
                res.html = '<span style="color:#ff464e">电话号码有误</span>'
                res.state = 0
            }
            if(!res.province || !res.city || !res.county || !res.address){
                res.html ='<span style="color:#ff464e">地址有误</span>'
                res.state = 0
            }
            if(res.name == ''){
                res.html ='<span style="color:#ff464e">收货人不能为空</span>'
                res.state = 0
            }
            if (res.sensitiveStr) {
                res.html = '<span style="color:red">请修改地址中存在敏感词：'+res.sensitiveStr+'</span>'
                res.state = 0
            }
            if(res.state === 0){
                this.errorList.push(index)
            }
            this.list.push(res)
        })
        this.list.find(res =>{
            if(res.state==0){
                this.state = true
                return true
            }else{
                this.state= false
            }
        })
    },
    mounted(){},
    watch: {},
    methods: {
        getHtmlContent(html){
            let htmles=html.split(">",2)
            let htmless=htmles[1].split("<",1)
            return htmless[0]
        },
        sortDataAkey() {
            this.$confirm('确定进行有误排序?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var count = 0;
                for (var i = 0; i < this.list.length; i++) {
                    if (this.list[i].state == 0) {
                        this.list.unshift(this.list.splice(i, 1)[0]);
                        count++;
                    }
                }
                this.errorList.splice(0, this.errorList.length);
                for (var j = 0; j < count; j++) {
                    this.errorList.push(j);
                }
            })
        },
        next(){
            if(this.errorList.length > 0){
                let arr = []
                this.errorList.forEach(res => {
                    arr.push(res + 1)
                })
                let str = arr.join(',')
                this.$message.error('第'+str+'行的收件人信息有误，请核对后再提交')
                return
            }

            let nameIndex = '';
            this.list.forEach((res,index) => {
                if(res.name.length>15){
                    if(nameIndex){
                        nameIndex = nameIndex+","+(index+1);
                    }else{
                        nameIndex = index+1;
                    }
                    return false;
                }
            })
            if(nameIndex){
                this.$message.warning('第'+nameIndex+'行收货人姓名不能超过15个字')
                return;
            }

            let obj = {
                list: this.list,
                platform: this.platform,
                importType: this.importType,
                shopId:this.shopId
            }
            let item = JSON.stringify(obj)
            sessionStorage.setItem('real_list_data',item)
            this.$router.push('/member/chose_goods')
        },
        del(index){
            this.$confirm('确定删除该数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.list.splice(index,1)
                //删除报错行
                let index1 = this.errorList.indexOf(index)
                this.errorList.splice(index1,1)
                
                this.list.find(res =>{
                    if(res.state==0){
                            this.state = true
                            return true
                        }else{
                            this.state= false
                        }
                    })
                })
        },
        showEdit(index){
            this.showTool = true
            this.editObj = this.list[index]
            this.editObj.index = index
        },
        checkEdit(){
            this.checkIndex = this.editObj.index
            this.list[this.checkIndex].html = '<span style="color:green">正确</span>'
            this.list[this.checkIndex].state = 1
            this.editObj.phone = this.editObj.phone.indexOf('-') != -1 ? this.editObj.phone.split('-')[1] : this.editObj.phone
            if (!this.$check.isPhone(this.editObj.phone)) {
                this.list[this.checkIndex].html = '<span style="color:#ff464e">电话号码有误</span>'
                
                this.list[this.checkIndex].state = 0
            }
            else if (!this.editObj.province || !this.editObj.city || !this.editObj.county || !this.editObj.address) {
                this.editObj.html = '<span style="color:#ff464e">地址有误</span>'
                this.editObj.state = 0
            }
            else if (this.editObj.name == '') {
                this.list[this.checkIndex].html = '<span style="color:#ff464e">收货人不能为空</span>'
            
                this.list[this.checkIndex].state = 0
            }else{
                let address = []
                address.push(this.editObj.province + " " + this.editObj.county + " " + this.editObj.city + " " + this.editObj.address)
                let item = JSON.stringify(this.editObj)
                this.list[this.editObj.index] = JSON.parse(item)
                this.$request.post({
                    url: '/bus/order/cutAddress',
                    params: {
                        belongTerrace: this.platform,
                        receiveAddress: address.join(',')
                    },
                    success: res => {
                        res.forEach((res) => {
                            let html = ''
                            if (!res.sensitiveStr) {
                                html = '<span style="color:green">正确</span>'
                                this.list[this.checkIndex].html = html
                                this.list[this.checkIndex].state = 1
                            } 
                            else if( res.sensitiveStr){
                                    html = '<span style="color:red">请修改地址中存在敏感词：'+res.sensitiveStr+'</span>'
                                    this.list[this.checkIndex].html = html
                                    this.list[this.checkIndex].state = 0
                            }
                
                            if (this.editObj.state === 0) {
                                if (this.errorList.indexOf(this.editObj.index) == -1) {
                                    this.errorList.push(this.editObj.index)
                                }
                            } else {
                                let index = this.errorList.indexOf(this.editObj.index)
                                if (index !== -1) {
                                    this.errorList.splice(index, 1)
                                }
                            }
                        })

                    }
                })
            }
            this.editObj = {
                phone: '',
                html: '',
                state: '',
                province: '',
                city: '',
                county: '',
                address: '',
                index: '',
            }
            this.editObj=this.list[this.checkIndex]
            this.list.find(res => {
                if (res.state == 0) {
                    this.state = true
                    return true
                } else {
                    this.state = false
                }
            })
            this.showTool = false
        },
        sortDataAkey(){
            this.$confirm('确定进行有误排序?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var count = 0;
                for(var i = 0; i < this.list.length; i++){
                    if(this.list[i].state == 0){
                        this.list.unshift(this.list.splice(i , 1)[0]);
                        count++;
                    }
                }
                this.errorList.splice(0,this.errorList.length);
                for(var j = 0; j < count; j++){
                    this.errorList.push(j);
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
#check_order{
    .send_buy_block{
        margin-bottom: 15px;
        background: #fff;
        padding: 30px;
        width: 100%;
        box-sizing: border-box;
        display: block;
        border-radius: 4px;
        .title{
            font-size: 16px;
            font-weight: bold;
            span{
                color: #f80;
                cursor: pointer;
                font-size: 12px;
                margin-left: 20px;
            }
        }
        .sub_title{
            color: #999;
            font-size: 14px;
            line-height: 35px;
        }
        .excel_list_check{
            table{
                border-collapse: collapse;
                width: 100%;
                font-size: 12px;
                line-height: 30px;
                font-weight: normal;
                border: 1px solid #efefef;
                color: #666;
                tr{
                    border-bottom: 1px solid #efefef;
                    &:first-child{
                        font-weight: bold;
                        background: #f5f5f5;
                        color: #333;
                    }
                }
                td{
                    border-right: 1px solid #efefef;
                    padding: 0 10px;
                    &:last-child{
                        border-right: none;
                    }
                    &.success{
                        color: #10b573;
                    }
                    &.error{
                        color: #ff464e;
                    }
                    &.handle{
                        p{
                            margin: 3px 0;
                        }
                    }
                }
            }
            .edit_div{
                margin-top: 10px;
                div{
                    display: inline-block;
                }
            }
        }
        .btn{
            margin-top: 20px;
            text-align: right;
            .el-button{
                background: #f80;
                color: #fff;
                border-color: #f80;
            }
            .is-disabled{
                background: #ffcf99;
                border-color: #ffcf99;
            }
        }
    }
    .input_put_block{
        .put_item{
            span{
                display: inline-block;
                width: 80px;
                text-align: right;
                margin-right: 10px;
            }
            input{
                border: 1px solid #efefef;
                outline: none;
                width: 360px;
                line-height: 36px;
                padding: 0 5px;
                margin-right: 10px;
                margin-bottom: 20px;
            }
        }
    }
}
</style>